import styled, { DefaultTheme } from "styled-components";
import { InputProps, scales } from "./types";

interface StyledInputProps extends InputProps {
  theme: DefaultTheme;
}

/**
 * Priority: Warning --> Success
 */
const getBoxShadow = ({ isSuccess = false, isWarning = false, theme }: StyledInputProps) => {
  if (isWarning) {
    return theme.shadows.warning;
  }

  if (isSuccess) {
    return theme.shadows.success;
  }

  return theme.shadows.inset;
};

const getHeight = ({ scale = scales.MD }: StyledInputProps) => {
  switch (scale) {
    case scales.SM:
      return "32px";
    case scales.LG:
      return "56px";
    case scales.MD:
    default:
      return "40px";
  }
};

const Input = styled.input.attrs({ autocomplete: "off" })<InputProps>`
  background: transparent;
  border-radius: 12px;
  color: #1a202c;
  display: block;
  font-size: 16px;
  height: ${getHeight};
  outline: 0;
  padding: 0 16px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};

  &::placeholder {
    color: #a0aec0;
  }

  &:disabled {
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }

  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

Input.defaultProps = {
  scale: scales.LG,
  isSuccess: false,
  isWarning: false,
};

export default Input;
